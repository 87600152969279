import { Component, OnInit } from '@angular/core';
import {StackNotificationService} from '../../services/stack-notification.service';

@Component({
  selector: 'app-stack-notification',
  templateUrl: './stack-notification.component.html',
  styleUrls: ['./stack-notification.component.scss']
})
export class StackNotificationComponent implements OnInit {
  expanded = false;
  constructor(
    private stackNotificationService: StackNotificationService
  ) { }

  ngOnInit() {
    this.stackNotificationService.getObservable().subscribe(notification => {
      console.log(notification);
    });
  }

  onClick(e) {
    this.expanded = true;
  }

}
