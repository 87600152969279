import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { adminMenu } from './menu.data';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu: Subject<any[]> = new Subject<any[]>();
  constructor(
    private authService: AuthService
  ) {
   }

  public generateMenu(userType: string) {
    // console.log(userType, adminMenu);
    switch (userType) {
      case 'admin': {
        this.menu.next(this.getFilteredMenuByPermissions(adminMenu));
        break;
      }
      default: this.menu.next([]);
    }
  }

  public getMenu(): Observable<any> {
    return this.menu;
  }

  getFilteredMenuByPermissions(menuItems) {
    return menuItems.filter(x => this.authService.hasAccessTo(x.key)).map(x => ({
      ...x,
      children: this.getFilteredMenuByPermissions(x.children)
    }));
  }

}
