<nz-tabset [nzTabBarExtraContent]="">
  <nz-tab nzTitle="Contact">
    <nz-page-header class="site-page-header">
      <nz-page-header-content>
        <div class="content">
          <div class="main">
            <nz-descriptions nzSize="small" [nzColumn]="2">
              <nz-descriptions-item nzTitle="Contact Person" [nzSpan]="1">{{
                rfq?.createdBy?.fullName | titlecase
              }}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Email" [nzSpan]="1"
                ><a>{{ rfq?.createdBy?.email }}</a></nz-descriptions-item
              >
              <nz-descriptions-item nzTitle="Email Verified" [nzSpan]="1"
                ><ng-container *ngIf="rfq?.createdBy?.emailVerified === true">
                  <nz-tag
                   nzColor="green"
                    >Yes</nz-tag
                  ></ng-container
                >
                <ng-container *ngIf="rfq?.createdBy?.emailVerified === false">
                  <nz-tag nzColor="red">No</nz-tag></ng-container
                >
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle=" No of Partners" [nzSpan]="1"
                >{{rfq?.company?.partners?.length}}
                </nz-descriptions-item
              >
              <nz-descriptions-item nzTitle="Country" [nzSpan]="2">
                {{ rfq?.company?.country | countryName }}
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
          <div class="extra">
            <div></div>
          </div>
        </div>
      </nz-page-header-content>

    </nz-page-header>
  </nz-tab>
  <nz-tab nzTitle="Company">
    <nz-page-header class="site-page-header">

      <nz-page-header-content>
        <div class="content">
          <div class="main">
            <nz-descriptions nzSize="small" [nzColumn]="2">
              <nz-descriptions-item nzTitle="Company" [nzSpan]="1"
                >{{rfq?.company?.companyName
                }}</nz-descriptions-item
              >

              <nz-descriptions-item nzTitle="Number Of Registered Ships/Projects" [nzSpan]="1"
                >{{rfq?.company?.noOfRegisteredVessels
                }}</nz-descriptions-item
              >
              <nz-descriptions-item nzTitle="Partners" [nzSpan]="1"
              ><ng-container *ngIf="rfq?.company?.partners?.length === 0"><nz-tag
                  nzColor="default"
                  >N/A
                  </nz-tag></ng-container>
                  <ng-container *ngIf="rfq?.company?.partners?.length > 0"><nz-tag
                      *ngFor="let partner of rfq?.company?.partners"
                      nzColor="blue"
                      >{{partner.name }}
                      </nz-tag></ng-container>
              </nz-descriptions-item
            >
              <nz-descriptions-item nzTitle="Address" [nzSpan]="2">
                {{rfq?.company?.address
                }}
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
          <div class="extra">
            <div></div>
          </div>
        </div>
      </nz-page-header-content>

    </nz-page-header>
  </nz-tab>
</nz-tabset>
