import { Pipe, PipeTransform } from '@angular/core';
import {countries} from '../data/countries';
@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

  transform(value: string): unknown {
    if(!value) {
      return '-';
    }
    const foundCountry =  countries.find(x => x.code.toLocaleLowerCase() === value.toLocaleLowerCase());
    if(foundCountry) {
      return foundCountry.name;
    }
    return value;
  }

}
