import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RequesterService } from 'src/app/common/services/requester.service';

@Component({
  selector: 'app-add-more-vendor-to-job',
  templateUrl: './add-more-vendor-to-job.component.html',
  styleUrls: ['./add-more-vendor-to-job.component.scss']
})
export class AddMoreVendorToJobComponent implements OnInit {
  rfqDetails: any = {};
  @Input() rfqId: string;
  loading = false;
  availableVendors: any[] = [];
  loadingAvailable = false;
  existingVendorIds: string[] = [];
  recommendedVendors: any[] = [];
  $searchText: Subject<string> = new Subject();
  search: string = '';
  filterEnabled: boolean = true;
  constructor(
    private httpRequestService: RequesterService
  ) { }

  ratingScore(scores): number {
    return scores.reduce((a, b) => a + b.score, 0) / scores.length;
  }
  async ngOnInit(): Promise<any> {
    this.$searchText.pipe(debounceTime(500)).subscribe(searchText => this.searchVendors(searchText));
    await this.getJobDetails();
    await this.getRecommendedVendors();
  }

  async getJobDetails(): Promise<any> {
    this.loading = true;
    const { data } = await this.httpRequestService.request('get', `jobs/rfqs/${this.rfqId}`).toPromise();
    this.rfqDetails = data;
    this.rfqDetails.selectedVendors.forEach(vendor => {
      this.existingVendorIds.push(vendor._id);
      vendor.rating = this.ratingScore(vendor.ratings || []);
    });
    this.loading = false;
  }

  async getRecommendedVendors(): Promise<any> {
    this.loadingAvailable = true;
    const url = this.filterEnabled ? `jobs/rfqs/${this.rfqId}/recommended-vendors` : `jobs/rfqs/search-vendors`;
    const { data } = await this.httpRequestService.request('get', url).toPromise();
    this.recommendedVendors = data;
    this.recommendedVendors.forEach(vendor => {
      vendor.recommended = true;
      vendor.rating = this.ratingScore(vendor.ratings || []);
    });
    this.availableVendors = [ ...this.recommendedVendors.filter(x => !this.existingVendorIds.includes(x._id))];
    this.loadingAvailable = false;
  }

  async searchVendors(searchText: string): Promise<any> {
    if(!searchText) {
      this.availableVendors = [ ...this.recommendedVendors.filter(x => !this.existingVendorIds.includes(x._id))];
      return;
    }
    this.loadingAvailable = true;
    const { data } = await this.httpRequestService.request('get', `jobs/rfqs/search-vendors?search=${searchText}`).toPromise();
    this.availableVendors = data.filter(x => !this.existingVendorIds.includes(x._id));
    this.availableVendors.forEach(vendor => {
      vendor.recommended = false;
      vendor.rating = this.ratingScore(vendor.ratings || []);
    });
    this.loadingAvailable = false;
  }

  async addVendorToJob(vendor): Promise<any> {
    this.loadingAvailable = true;
    await this.httpRequestService.request('put', `jobs/rfqs/${this.rfqId}/send-rfq`, {
      selectedVendors: [...this.existingVendorIds, vendor._id],
    }).toPromise();
    await this.ngOnInit();
  }
}
