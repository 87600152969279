<div class="modalContent">
  <nz-card [nzTitle]="titleTpl" style="margin-bottom: 10px;" [nzExtra]="searchItem">
    <ng-template #titleTpl>
      Available Vendors
      <label style="margin-left: 10px;" nz-checkbox [(ngModel)]="filterEnabled" (ngModelChange)="getRecommendedVendors()">Filter by Port, Service, Equipment & Brand</label>
    </ng-template>

    <ng-template #searchItem>
      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
        <input type="text" nz-input [(ngModel)]="search" (ngModelChange)="$searchText.next(search)" placeholder="input search text" />
      </nz-input-group>
    </ng-template>
    <ng-template #suffixIconButton>
      <button nz-button nzType="primary" nzSearch><i nz-icon nzType="search"></i></button>
    </ng-template>

    <nz-table #availableVendorsTbl [nzData]="availableVendors" [nzShowPagination]="false" [nzLoading]="loadingAvailable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Contact</th>
          <th>Rating</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of availableVendorsTbl.data">
          <td>{{data.companyName}} <nz-tag *ngIf="data.recommended" nzColor="blue">Recommended</nz-tag></td>
          <td>{{data.owner.fullName}}<br/>{{data.owner.email}}</td>
          <td>
            <nz-rate [ngModel]="data.rating" nzAllowHalf nzDisabled></nz-rate>
          </td>
          <td>
            <button nz-button nzType="primary" (click)="addVendorToJob(data)">Add to Job</button>
          </tr>
      </tbody>
    </nz-table>
  </nz-card>

  <nz-card nzTitle="Existing Vendors">
    <nz-table #selectedVendors [nzData]="rfqDetails.selectedVendors" [nzShowPagination]="false" [nzLoading]="loading">
      <thead>
        <tr>
          <th>Name</th>
          <th>Contact</th>
          <th>Rating</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of selectedVendors.data">
          <td>{{data.companyName}}</td>
          <td>{{data.owner.fullName}}<br/>{{data.owner.email}}</td>
          <td>
            <nz-rate [ngModel]="data.rating" nzAllowHalf nzDisabled></nz-rate>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-card>

</div>
