import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { LayOutRoutingModule } from './lay-out-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SidebarComponent } from './main-layout/sidebar/sidebar.component';
import { HeaderComponent } from './main-layout/header/header.component';
import { FooterComponent } from './main-layout/footer/footer.component';
import { ChatModule } from '../main/chat/chat.module';
import { SharedModuleModule } from '../common/shareModule/shareModule';
import { ChangePasswordComponent } from './main-layout/header/change-password/change-password.component';
import { GlobalSearchComponent } from './main-layout/header/global-search/global-search.component';

@NgModule({
    declarations: [
        MainLayoutComponent,
        AuthLayoutComponent,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        ChangePasswordComponent,
        GlobalSearchComponent
    ],
    imports: [
        CommonModule,
        LayOutRoutingModule,
        NzLayoutModule,
        SharedModuleModule,
        ChatModule,
        SharedModuleModule
    ],
    providers: [{ provide: NZ_I18N, useValue: en_US }]
})
export class LayOutModule {}
