<nz-card>
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title>{{ rfqDetails.rfqNo }}</nz-page-header-title>
    <nz-page-header-subtitle>Job Overview</nz-page-header-subtitle>
    <nz-page-header-extra>
      <button nz-button nzType="primary" (click)="chatWithOwner()">
        <i nz-icon nzType="message"></i> Chat with Owner
      </button>
      <button nz-button nzType="danger" *ngIf="rfqDetails.jobStatus > 9">
        <i nz-icon nzType="tool" nzTheme="outline"></i> Workspace
      </button>
    </nz-page-header-extra>
    <nz-page-header-content>
      <nz-descriptions nzSize="small" [nzColumn]="3">
        <nz-descriptions-item nzTitle="Owner" [nzSpan]="1">{{
          rfqDetails.company?.companyName
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Created By" [nzSpan]="1">{{
          rfqDetails.createdBy?.fullName
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Created At" [nzSpan]="1">{{
          rfqDetails.createdAt | date : "mediumDate"
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Job Status" [nzSpan]="1"
          ><nz-tag>{{
            statusService.jobStatus[rfqDetails.jobStatus]
          }}</nz-tag></nz-descriptions-item
        >
        <nz-descriptions-item nzTitle="Ports" [nzSpan]="2">
          <nz-tag *ngFor="let item of rfqDetails.ports">{{
            item.port.name
          }}</nz-tag>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-page-header-content>
  </nz-page-header>
</nz-card>

<nz-card nzTitle="Owner Details">
  <app-quick-owner-detail [rfq]="rfqDetails"></app-quick-owner-detail>
</nz-card>

<app-bids-on-rfq
  [rfqId]="rfq"
  [fromPopup]="true"
  (closePopup)="close()"
></app-bids-on-rfq>

<nz-card nzTitle="PO Details" *ngIf="rfqDetails.jobStatus > 6">
  <app-job-po-list [rfq]="rfqDetails" (closeDrawer)="close()"></app-job-po-list>
</nz-card>

<ng-container *ngIf="rfqDetails.jobStatus < 18">
  <nz-card nzTitle="SR Details" *ngIf="rfqDetails.jobStatus > 11">
    <app-media-box
      [files]="rfqDetails.serviceReport.attachments"
      [itemsPerLine]="4"
    ></app-media-box>
  </nz-card>

  <nz-card nzTitle="Invoice Details" *ngIf="rfqDetails.jobStatus > 13">
    <nz-descriptions nzSize="small" [nzColumn]="1" [nzBordered]="true">
      <nz-descriptions-item nzTitle="Owner Invoice Status" [nzSpan]="1"
        ><a (click)="openInvoice()">{{
          rfqDetails?.invoicePaid[0] === "owner" ? "Paid" : "Pending Payment"
        }}</a></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Vendor Invoice Status" [nzSpan]="1"
        ><a (click)="openInvoice()">{{
          rfqDetails?.invoicePaid[1] === "vendor" ? "Paid" : "Pending Payment"
        }}</a></nz-descriptions-item
      >

      <ng-container>
        <nz-descriptions-item nzTitle="Vendor Company" [nzSpan]="1">
        
          {{
            invoiceDetails?.invoiceFrom?.companyName
          }}</nz-descriptions-item
        >
        <nz-descriptions-item nzTitle="Grand Total" [nzSpan]="1">
          {{ invoiceDetails?.invoiceCurrency }}
          {{
            invoiceDetails?.invoiceNetDue | number : "1.2-2"
          }}</nz-descriptions-item
        >
        <nz-descriptions-item nzTitle="Attender Fees" [nzSpan]="1">
          {{ invoiceDetails?.invoiceCurrency }}
          {{
            invoiceDetails?.spoolifyFeesAmountForExtraActivities +
              invoiceDetails?.spoolifyFeesAmount | number: '1.2-2'
          }}</nz-descriptions-item
        >
       
      </ng-container>
    </nz-descriptions>
  </nz-card>
</ng-container>

<nz-card nzTitle="RFQ Timeline">
  <nz-timeline>
    <nz-timeline-item *ngFor="let item of timelineData">
      <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
      {{ item.createdAt | date : "medium" }}
      <strong>{{ item.eventDetails }}</strong>
    </nz-timeline-item>
  </nz-timeline>
</nz-card>
