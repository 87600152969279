import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';


const routes: Routes = [{
path: 'auth-layout',
component: AuthLayoutComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes), NzDropDownModule],
  exports: [RouterModule]
})
export class LayOutRoutingModule { }
