import {Component, Input, OnInit} from '@angular/core';
import { MenuService } from 'src/app/common/services/menu.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/common/services/socket.service';
import { Subscription } from 'rxjs';
import { ChatCounterService } from 'src/app/common/services/chat-counter.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() isCollapsed = false;
  menuData: any[] = [];
  unreadMsgTotal: number;
  currentUrl = '';
  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private router: Router,
    private chatCounterService: ChatCounterService,
    private notification: NzNotificationService,

  ) {
    this.currentUrl = this.router.url;
    this.menuService.getMenu().subscribe(menuData => {
      console.log('Menu Generated');
      this.menuData = menuData;
    });
    this.authService.permissions$.subscribe(permissions => {
      console.log('Generating Menu');
      this.menuService.generateMenu('admin');
    });
  }

  ngOnInit() {
    console.log('this is side bar');
    this.chatCounterService.unreadChatCount.subscribe(totalUnreadChat => {
      console.log('total Unread Chat', totalUnreadChat);
      this.unreadMsgTotal = Number(totalUnreadChat);
    });
  }

}
