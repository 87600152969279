import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-single-floating',
  templateUrl: './chat-single-floating.component.html',
  styleUrls: ['./chat-single-floating.component.scss']
})
export class ChatSingleFloatingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
