<nz-tabset>
  <nz-tab nzTitle="Active PO">
    <nz-descriptions nzSize="small" [nzColumn]="4">
      <nz-descriptions-item nzTitle="PO Number" [nzSpan]="1" >
        <nz-tag (click)="openPO()" nzColor="blue" style="cursor: pointer;">{{activePo.poNumber}}</nz-tag>

      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Created At" [nzSpan]="1">{{activePo.createdAt | date: 'mediumDate'}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Owner PO Status" [nzSpan]="1"><nz-tag nzColor="blue" style="cursor: pointer;" (click)="openPO()">{{statusService.poStatus[activePo.poStatus]}}</nz-tag></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Vendor PO Status" [nzSpan]="1"><nz-tag nzColor="blue" style="cursor: pointer;" (click)="openPO()">
        {{activePo.rejectedByVendor ? 'PO Resubmission Requested' : statusService.poStatus[activePo.poStatus]}}
      </nz-tag></nz-descriptions-item>
    </nz-descriptions>
  </nz-tab>
  <nz-tab nzTitle="PO History">
    <nz-table #basicTable [nzData]="poHistory">
      <thead>
        <tr>
          <th>PO Number</th>
          <th>Vendor</th>
          <th>Status</th>
          <th>Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td><nz-tag nzColor="red" style="cursor: pointer;" (click)="openPO(data._id)">{{data.poNumber}}</nz-tag></td>
          <td>{{data.bid?.vendorCompany?.companyName}}</td>
          <td>Cancelled</td>
          <td>{{data.cancelPOReasonByOwner}}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>
