export const modulesMap = {
  vendors: 'vendor',
  owners: 'owner',
  'service-management': 'master-resource/service',
  'equipment-management': 'master-resource/equipment',
  'brand-management': 'master-resource/brand',
  'manage-jobs': 'job',
  'all-jobs': 'job',
  'chats': 'job',
  'bulk-upload': 'job',
  'job-payments': 'finance',
  'currency-exchange': 'finance',
  'admin-users': 'user',
  'admin-management': 'user',
  'admin-group': 'user',
  partner: 'user',
  'owner-vendor-users': 'user',
  'job-bid-invoice': 'job'
};
