import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequesterService } from '../../../common/services/requester.service';
import { ChatDispatcherService } from '../../chat/services/chat-dispatcher.service';
import {Location} from '@angular/common';
import { StatusService } from 'src/app/common/services/status.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddMoreVendorToJobComponent } from './add-more-vendor-to-job/add-more-vendor-to-job.component';


@Component({
  selector: 'app-bids-on-rfq',
  templateUrl: './bids-on-rfq.component.html',
  styleUrls: ['./bids-on-rfq.component.scss']
})
export class BidsOnRfqComponent implements OnInit, OnChanges {
  rfqDetails: any = {};
  activeBidsList: any[] = [];
  @Input() rfqId: string;
  noBidsList: any[] = [];
  awaitedBidsList: any[] = [];
  awardedBid: string = '';
  poDetails: any = {};
  chatLoading = false;
  @Input() fromPopup = false;
  @Input() fromChat = false;
  @Output() openChat: EventEmitter<any> = new EventEmitter<any>();
  @Input() vendorUnreadCounts: any = {};
  @Output() closePopup: EventEmitter<any> = new EventEmitter<any>();
  activeBidsVendorList: any;
  selectedBid:any;
  allBidList: any[] = [];
  bidListRecord: Record<string,any>;
  constructor(
    private route: ActivatedRoute,
    private requesterService: RequesterService,
    private chatDispatcherService: ChatDispatcherService,
    private location: Location,
    public statusService: StatusService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {
  }
  updateChatLoading() {
    this.chatLoading = true;
    setTimeout(() => {
      this.chatLoading = false;
    }, 4000);
  }
  updateChatLoadingForbid(data) {
    data.chatLoading = true;
    setTimeout(() => {
      data.chatLoading = false;
    }, 4000);
  }

  ngOnInit() {
    // console.log('BIDS')
    if(!this.rfqId) {
      this.rfqId = this.route.snapshot.paramMap.get('rfqId');
    }
    this.getRfqDetails();
    this.getBids();
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(this.fromChat && changes.rfqId) {
      // console.log('BIDS');
      this.ngOnInit();
    }
  }

  getRfqDetails() {
    this.requesterService.request('get', 'jobs/rfqs/' + this.rfqId).subscribe(data => {
      this.rfqDetails = data.data;
      if(this.rfqDetails.po) {
        this.requesterService.request('get', 'jobs/pos/owner/' + this.rfqDetails.po).subscribe(response => {
          this.poDetails = response.data;
          this.awardedBid = response.data.bid.vendorBid;
        })
      }
      this.getAwaitedBids();
    });
  }
  getBids() {
    this.requesterService.request('get', `jobs/bids/by-rfq/${this.rfqId}`).subscribe(data => {
      this.activeBidsList = data.data.filter( x => [2, 3, 4, 5, 6].includes(x.bidStatus));
      this.noBidsList = data.data.filter( x => x.bidStatus === 0);
      this.getAwaitedBids();
      this.bidListRecord = {
        ['ACTIVE']: this.activeBidsList,
        ['AWAITED']: this.awaitedBidsList,
        ['NO_BID']: this.noBidsList
    }

    });
  }

  getAwaitedBids() {
    if (this.rfqDetails.selectedVendors) {
      this.awaitedBidsList = this.rfqDetails.selectedVendors.filter( x => ![ ...this.activeBidsList, ...this.noBidsList].map(x => x.vendorCompany._id).includes(x._id));
    }

  }

  chatWithVendor(vendorCompany: any) {
    if(this.fromChat) {
      this.openChat.emit(vendorCompany);
      return;
    }
    this.requesterService.request('get', 'jobs/rfqs/' + this.rfqId + '/initiate-chat?chatWith=vendor&vendor=' + vendorCompany._id).subscribe(data => {
      this.chatDispatcherService.createOrUpdateChannel(data.data.channel);
    });
  }

  chatWithOwner() {
    this.requesterService.request('get', 'jobs/rfqs/' + this.rfqId + '/initiate-chat?chatWith=owner').subscribe(data => {
      this.chatDispatcherService.createOrUpdateChannel(data.data.channel);
    });
  }
  backToPreviousPage() {
    this.location.back();
  }

  getBidStatusForVendor(bid: any) {
    if(this.rfqDetails.jobStatus > 9 && !(this.poDetails.bid && this.poDetails.bid._id === bid.ownerBid)) {
      return 'Lost'
    }

    if(bid.bidStatus <= 5 && this.rfqDetails.jobStatus < 10) {
      if(this.poDetails.bid && this.poDetails.bid._id === bid.ownerBid) {
        return bid.poResubmissionRequested ? 'PO Resubmission Requested' : this.statusService.bidStatus[bid.bidStatus];
      } else {
        return this.statusService.bidStatus[bid.bidStatus];
      }
    }


    if(this.rfqDetails.jobStatus === 15) {
      return this.rfqDetails.invoicePaid && this.rfqDetails.invoicePaid.includes('vendor') ? 'Paid' : 'Pending Payment'
    }

    if(this.rfqDetails.jobStatus > 12 && this.rfqDetails.jobStatus <=14) {
      return this.rfqDetails.invoiceResubmittedByAdmin ? this.statusService.invoiceStatus[4] : this.statusService.jobStatus[this.rfqDetails.jobStatus];
    }

    return this.statusService.jobStatus[this.rfqDetails.jobStatus];


  }

  openAddVendorModal(): void {
    const modal = this.modal.create({
      nzTitle: 'Add More Vendors',
      nzContent: AddMoreVendorToJobComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzWidth: 1000,
      nzComponentParams: {
        rfqId: this.rfqId
      },
      nzOnOk: () => new Promise(resolve => {
        this.ngOnInit();
        resolve();
      }),
      nzOkText: 'Done',
      nzCancelText: null
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => {
      this.getBids();
    });
  }
//get all kind of bids
  // getAllBids(){
  //   this.requesterService.request('get', `jobs/bids/by-rfq/${this.rfqId}`).subscribe(data => {
  //     console.log('Rityparana',data);
  //   });
  // }
  // activeBidVendorDetail(index:any){
  //     this.selectedBid = this.activeBidsList[index];

    
  // }
  // awaitedBidVendorDetail(index : number){
  //   this.selectedBid = this.awaitedBidsList[index];

  // }

  // noBidVendorDetail(index : number){
  //   this.selectedBid = this.noBidsList[index];

  // }

  vendorDetails(index: number, bidType:string){
    const data = this.bidListRecord[bidType];
    this.selectedBid = data[index];
  }

  
}
