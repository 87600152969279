import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { RequesterService } from "src/app/common/services/requester.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  buttonLoading = false;
  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private notificationService: NzNotificationService,
    private requesterService: RequesterService,
    private router: Router,
    private modalRef: NzModalRef
  ) {
    this.changePasswordForm = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmPassword: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });
  }

  ngOnInit() {}

  /* Submit change password Form */
  submit(): void {
    if (!this.changePasswordForm.valid) {
      this.markFormGroupTouched(this.changePasswordForm);
    } else {
      console.log(this.changePasswordForm.value);
      this.changePass("post", "auth/change-password", "Password Updated Successfully");
    }
  }

  /* modify  change password */
  changePass(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;

    this.requesterService
      .request(requestMethod, requestURL, {password: this.changePasswordForm.value.newPassword})
      .subscribe(
        (result: any) => {
          this.notificationService.success("SUCCESS", successMessage);
          this.buttonLoading = false;
          this.modalRef.close();
        },
        (error: any) => {
          this.notificationService.error("ERROR", error.error.message);
          this.buttonLoading = false;
        }
      );
  }

  /* CONFIRM PASSWORD VALIDATION */
  confirmationValidator = (formGroup: UntypedFormGroup): { [s: string]: boolean } => {
    if (!formGroup.value) {
      return { required: true };
    } else if (
      formGroup.value !== this.changePasswordForm.controls.newPassword.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.changePasswordForm.controls.confirmPassword.updateValueAndValidity()
    );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: UntypedFormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
