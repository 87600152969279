<nz-tabset [nzTabBarExtraContent]="">
    <nz-tab nzTitle="Contact">
      <nz-page-header class="site-page-header">
        <nz-page-header-content>
          <div class="content">
            <div class="main">
              <nz-descriptions nzSize="small" [nzColumn]="2">
                <!-- <div class="des-container"> -->
                <nz-descriptions-item  nzTitle="Contact Person" [nzSpan]="1" ><nz-tag (click)="gotoDetails(vendordetails?.vendorCompany?._id)"  nzType="default" >{{
                    vendordetails?.createdBy?.fullName | titlecase
                }}</nz-tag></nz-descriptions-item>
              <!-- </div> -->
                
                <nz-descriptions-item nzTitle="Email"
                  ><a>{{ vendordetails?.createdBy?.email }}</a></nz-descriptions-item
                >
                <nz-descriptions-item nzTitle="Email Verified" 
                  ><ng-container *ngIf="vendordetails?.createdBy?.emailVerified === true">
                    <nz-tag
                      style="
                        color: #389e0d;
                        background: #f6ffed;
                        border-color: #b7eb8f;
                      "
                      >Yes</nz-tag
                    ></ng-container
                  >
                  <ng-container *ngIf="vendordetails?.createdBy?.emailVerified === false">
                    <nz-tag style="    color: #cf1322;
                    background: #fff1f0;
                    border-color: #ffa39e;">No</nz-tag></ng-container
                  >
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle=" No of Partners" [nzSpan]="1"
                  >{{vendordetails?.vendorCompany?.partners?.length}}
                  </nz-descriptions-item
                >
                <nz-descriptions-item nzTitle="Country" [nzSpan]="2">
                  {{ vendordetails?.vendorCompany?.basicDetails?.countryName | countryName }}
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div class="extra">
              <div></div>
            </div>
          </div>
        </nz-page-header-content>
      
      </nz-page-header>
    </nz-tab>
    <nz-tab nzTitle="Company">
      <nz-page-header class="site-page-header">
      
        <nz-page-header-content>
          <div class="content">
            <div class="main">
              <nz-descriptions nzSize="small" [nzColumn]="2">
                <nz-descriptions-item class="des-container" nzTitle="Company" [nzSpan]="1" 
                  ><nz-tag (click)="gotoDetails(vendordetails?.vendorCompany?._id)"  nzType="default" >{{vendordetails?.vendorCompany?.companyName
                  }}</nz-tag></nz-descriptions-item
                >
               
                <nz-descriptions-item nzTitle="Company Owner" [nzSpan]="1"
                  >{{vendordetails?.vendorCompany?.owner?.fullName | titlecase
                  }}</nz-descriptions-item
                >
                <nz-descriptions-item nzTitle="Partners" [nzSpan]="1"
                ><ng-container *ngIf="vendordetails?.vendorCompany?.partners?.length === 0"><nz-tag
                    nzColor="default"
                    >N/A
                    </nz-tag></ng-container>
                    <ng-container *ngIf="vendordetails?.vendorCompany?.partners?.length > 0"><nz-tag
                        *ngFor="let partner of vendordetails?.vendorCompany?.partners"
                        nzColor="blue"
                        >{{partner.name }}
                        </nz-tag></ng-container>
                </nz-descriptions-item
              >
                <nz-descriptions-item nzTitle="Address" [nzSpan]="2">
                  {{vendordetails?.vendorCompany?.basicDetails?.addressLine1 + vendordetails?.vendorCompany?.basicDetails?.addressLine2
                  }}
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div class="extra">
              <div></div>
            </div>
          </div>
        </nz-page-header-content>
       
      </nz-page-header>
    </nz-tab>
  </nz-tabset>
  