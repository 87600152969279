import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class RequesterService {
  baseUrl = environment.apiUrl;
  static getAuthToken() {
    return localStorage.getItem('token');
  }
  constructor(private httpClient: HttpClient, private router: Router) {}

  public requestWithFile(
    method: string,
    endpoint: string,
    data
  ): Observable<any> {
    const headerObj = {
      Authorization: 'Bearer ' + RequesterService.getAuthToken(),
    };
    let formData = new FormData();
    if (!(data instanceof FormData)) {
      for (const key of Object.keys(data)) {
        const value = data[key];
        formData.append(key, value);
      }
    } else {
      formData = data;
    }
    if (method === 'post') {
      return this.httpClient.post(`${this.baseUrl}/api/${endpoint}`, formData, {
        headers: headerObj,
      });
    } else if (method === 'put') {
      return this.httpClient.put(`${this.baseUrl}/api/${endpoint}`, formData, {
        headers: headerObj,
      });
    } else {
      return throwError('Invalid Request method');
    }
  }

  public fileRequest(endpoint: string, data?: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/${endpoint}`, data, {
      headers: {
        Authorization: `Bearer ${RequesterService.getAuthToken()}`,
      },
    });
  }

  public request(
    method: string,
    endpoint: string,
    data?: any,
    authorised: boolean = true
  ): Observable<any> {
    let base: any;
    const headerObj = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + RequesterService.getAuthToken(),
    };
    if (!authorised) {
      delete headerObj.Authorization;
    }
    switch (method) {
      case 'post': base = this.httpClient.post(`${this.baseUrl}/api/${endpoint}`, data, { headers: headerObj });
                   break;
      case 'put': base = this.httpClient.put(`${this.baseUrl}/api/${endpoint}`, data, { headers: headerObj });
                  break;
      case 'delete': base = this.httpClient.delete(`${this.baseUrl}/api/${endpoint}`, { headers: headerObj });
                     break;
      default: base = this.httpClient.get(`${this.baseUrl}/api/${endpoint}`, { params: data, headers: headerObj });
    }
    return base.pipe(
      catchError((err, caught): any => {
        console.log(err)
        if(err.status === 401) {
          localStorage.clear();
          this.router.navigateByUrl('/auth/signin');
        }
        return throwError(err)
        // return of('error', err)
      })
    );
  }
}
