import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './lay-out/main-layout/main-layout.component';
import { AuthLayoutComponent } from './lay-out/auth-layout/auth-layout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { AuthRevGuard } from './common/guards/auth-rev.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main'
      },
      {
        path: 'main',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
      },
      // },
      // {
      //   path: '**', pathMatch: 'full',
      //   redirectTo: 'welcome'
      // }


    ],
  },
  {
    path: 'auth',
    // canActivate: [AuthRevGuard],
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)

      },
    ],
  },




];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
