import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  public bidStatus: any = {
    0: 'No Bid',
    1: 'Draft',
    2: 'Submitted',
    3: 'Submitted',
    4: 'Resubmission Requested',
    5: 'Awaiting PO Confirmation',
    6: 'Lost'
  };
  public bidStatusColor: any = {
    0: '#f50',
    1: 'orange',
    2: 'green',
    3: 'green',
    4: 'red',
    5: 'green',
    6: '#f50'
  };
  public jobStatus: any = {
    1: 'Draft',
    2: 'New',
    3: 'Awaiting Approval',
    4: 'Rejected',
    5: 'Approved',
    6: 'Awaiting Quotes',
    7: 'Quotes Received',
    8: 'Awaiting PO Confirmation',
    9: 'Awaiting PO Confirmation',
    10: 'Awarded',
    11: 'Completed',
    12: 'Pending Invoice',
    13: 'Awaiting Invoice Approval',
    14: 'Pending Payment',
    15: 'Paid',
    16: 'Closed',
    17: 'Awaiting Dispute Resolution',
    18: 'Cancelled'
  };

  public invoiceStatus: any = {
    1: 'Draft',
    2: 'Awaiting Invoice Approval',
    3: 'Awaiting Invoice Approval',
    4: 'Invoice Resubmission Requested',
    5: 'Invoice Accepted'
  };

  public poStatus: any = {
    1: 'Draft',
    2: 'PO Sent',
    3: 'PO Sent',
    4: 'PO Resubmission Requested',
    5: 'PO Active',
    6: 'PO Cancelled'
  };
  constructor() { }
}
