import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { ChannelData } from 'stream-chat';
import { ChatDispatcherService } from 'src/app/main/chat/services/chat-dispatcher.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { StreamClientService } from 'src/app/common/services/stream-client.service';
import * as moment from 'moment';
import { CommunicationService } from 'src/app/common/services/communication.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  isCollapsed = false;
  unreadMsgTotal = 0;
  unreadCount = 0;
  channelList: ChannelData[] = [];
  mobileSearch: boolean = false;

  @ViewChild('inpSearch', {static: false}) inpSearch: ElementRef<any>;
  showPopover = false;
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  inputTextContent: string;
  notifications: any[] =[];
  masterOpen = true;
  inBreakPoint: boolean = false;

  constructor(
    private chatDispatcherServ: ChatDispatcherService,
    private notificationService: NzNotificationService,
    private streamClientService: StreamClientService,
    public communicationService: CommunicationService,
    private breakpointObserver: BreakpointObserver

  ) {

    this.breakpointObserver.observe([
      "(max-width: 920px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        console.log("Matches", result);
          this.inBreakPoint = true;
      } else {
          this.inBreakPoint = false;

      }
    });
   }

  ngOnInit() {
    this.chatDispatcherServ.notificationCounter.subscribe( subscribedData => {
      this.unreadCount = subscribedData.count;
      this.channelList = subscribedData.channelList;
    });
    setTimeout(() => {
      if (this.unreadCount > 0) {
        // this.notificationService.info('Reminder', 'You have unread messages');
      }
    }, 5000);

    this.streamClientService.$allNotifications.subscribe(data => {
      this.notifications = [];
      data.notificationsByGroup.forEach(group => {
        const allActivities = group.activities.map(x => ({ ...x, is_read: group.is_read, is_seen: group.is_seen}));
        this.notifications.push( ...allActivities);
      });
    });
    this.streamClientService.getRealtimeNotification().subscribe(data => {
      this.notificationService.info(data.object, moment(data.time).format('LLL'));
    })
  }

  markAllRead() {
    this.streamClientService.markNotificationAsRead(this.notifications.map(x => x.id))
  }
  openChat(cid) {
    this.chatDispatcherServ.openChat.next(cid);
  }
  
// detect the screen size

  onSearch(text: any) {
    this.searchText.next(text.target.value);
  }

  get searchTextObservable() {
    return this.searchText as Observable<string>;
  }
  closeSearch() {
    this.showPopover = false;
  }

  clearSearch() {
    this.inputTextContent = '';
    this.searchText.next('');
    localStorage.removeItem('searchParams');
    localStorage.removeItem('searchKeyword');
    if (this.inpSearch) {

      this.inpSearch.nativeElement.focus();
    }
    // this.closeSearch();
  }

  openSearchbar() : void{
    this.mobileSearch = !this.mobileSearch;
  }

  

}
