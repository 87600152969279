<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="xl"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
    (mouseover)="!masterOpen ? (isCollapsed = false) : null"
    (mouseleave)="!masterOpen ? (isCollapsed = true) : null"
    [ngClass]="!masterOpen && !isCollapsed ? 'hide-menu' : ''"
  >
    <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
  </nz-sider>
  <nz-layout class="right-content">
    <nz-header>
      <div nz-row>
        <div nz-col nzSpan="18">
          <div
            class="app-header"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-right: 80px;
            "
          >
            <span
              class="header-trigger"
              (click)="isCollapsed = !isCollapsed; masterOpen = !masterOpen"
            >
              <i
                class="trigger"
                nz-icon
                [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
              ></i>
            </span>

            <!-- <div nz-col nzXXl="10" nzXl="10" nzLg="10" nzMd="10" nzSm="10" nzXs="10"> -->

            <div
              class="top-search-container"
              nzXXl="16"
              nzXl="16"
              nzLg="16"
              nzMd="0"
              nzSm="0"
              nzXs="0"
            >
              <nz-avatar
                [nzIcon]="mobileSearch ? 'close' : 'search'"
                class="mobileonly"
                (click)="openSearchbar()"
              ></nz-avatar>
              <nz-input-group
                nzSearch
                [nzAddOnAfter]="suffixIconButton"
                nz-popover
                [nzPopoverTitle]="null"
                [nzPopoverPlacement]="'bottom'"
                [(nzPopoverVisible)]="showPopover"
                [nzPopoverContent]="contentTemplate"
                nzPopoverTrigger="click"
                class="header-search-zone"
                [ngClass]="mobileSearch ? 'opened' : ''"
              >
                <input
                  type="text"
                  nz-input
                  placeholder="Search for..."
                  [(ngModel)]="inputTextContent"
                  (keyup)="onSearch($event)"
                  #inpSearch
                  class="inputbox-search"
                />
              </nz-input-group>
              <ng-template #suffixIconButton>
                <button
                  nz-button
                  nzType="primary"
                  nzSearch
                  (click)="clearSearch(); showPopover = false"
                >
                  <i
                    nz-icon
                    [nzType]="inputTextContent ? 'close' : 'search'"
                  ></i>
                </button>
              </ng-template>
              <ng-template #contentTemplate>
                <app-global-search
                  [searchText]="searchTextObservable"
                  (onClose)="closeSearch()"
                  (onClear)="clearSearch()"
                >
                  <!-- <nz-input-group *ngIf="inBreakPoint"
                  nzSearch
                  [nzAddOnAfter]="suffixIconButton"
                  nz-popover
                  [nzPopoverTitle]="null"
                  [nzPopoverPlacement]="'bottom'"
                  [(nzPopoverVisible)]="showPopover"
                  [nzPopoverContent]="contentTemplate"
                  nzPopoverTrigger="click"
                  class="header-search-zone"
                >
                  <input
                    type="text"
                    nz-input
                    placeholder="Search for..."
                    [(ngModel)]="inputTextContent"
                    (keyup)="onSearch($event)"
                    #inpSearch
                    class="inputbox-search"
                  />
                </nz-input-group> -->
                </app-global-search>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          nz-col
          nzSpan="6"
          class="app-header"
          style="
            display: flex;
            justify-content: end;
            align-items: center;
            padding-right: 24px;
            gap: 0 10px;
          "
        >
          <!-- <nz-badge [nzCount]="unreadCount" class="icon message-icon">
            <nz-avatar
              nzIcon="message"
              [nzShape]="'circle'"
              (click)="communicationService.toggleFloatingChat.next()"
              style="cursor: pointer"
            ></nz-avatar>
          </nz-badge> -->
          <nz-badge [nzCount]="unreadCount" class="icon">
            <nz-avatar
              nzIcon="bell"
              [nzShape]="'circle'"
              nz-popover
              nzPopoverTrigger="click"
              [nzPopoverTitle]="notificationHeader"
              [nzPopoverPlacement]="'bottomRight'"
              [nzPopoverContent]="messageList"
              [nzPopoverOverlayClassName]="'menuPopover'"
            ></nz-avatar>
          </nz-badge>
          <app-header></app-header>
        </div>
      </div>

      <!-- </div> -->
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>

    <!-- footer -->
    <!-- <nz-footer>
      <div nz-row>
        <div nz-col nzSpan="24">
          <div class="app-footer">
            <span>
              <app-footer></app-footer>
            </span>
          </div>
        </div>

      </div>
    </nz-footer> -->
  </nz-layout>
</nz-layout>
<div class="floating-chat">
  <app-floating-chat></app-floating-chat>
</div>

<ng-template #notificationHeader>
  All Notification
  <!-- <button nz-button nzSize="small" (click)="markAllRead()">Mark all as read</button> -->
</ng-template>

<ng-template #messageList>
  <div class="notificationContent">
    <ul>
      <li *ngIf="unreadCount" (click)="communicationService.toggleFloatingChat.next()">
        <p class="content">
          <i style="color: red;" nz-icon nzType="message" nzTheme="outline"></i>
          {{unreadCount}} unread messages
        </p>
        <!-- <p class="time">At: {{ notItem.time | date : "medium" }}</p> -->
      </li>
      <li *ngFor="let notItem of notifications">
        <p class="content">{{ notItem.object }}</p>
        <p class="time">At: {{ notItem.time | date : "medium" }}</p>
      </li>
    </ul>
    <!-- <nz-list [nzDataSource]="notifications" [nzRenderItem]="item1" [nzItemLayout]="'horizontal'">
      <ng-template #item1 let-item>
        <nz-list-item>
          <nz-list-item-meta [nzTitle]="item.object" nzDescription="{{item.date}}">
          </nz-list-item-meta>
        </nz-list-item>
      </ng-template>
    </nz-list> -->
  </div>
</ng-template>

<!--
<ng-template #messageList>
    <div>
      <nz-list [nzDataSource]="channelList" [nzRenderItem]="item1" [nzItemLayout]="'horizontal'">
        <ng-template #item1 let-item>
          <nz-list-item>
            <nz-list-item-meta [nzTitle]="nzTitle1"
              nzAvatar="https://ui-avatars.com/api/?background=ff5757&color=fff&name={{item.data.name}}"
              nzDescription="{{item.data.last_message_at | date : 'medium'}}">
              <ng-template #nzTitle1>
                <span style="cursor: pointer" (click)="openChat(item.cid)">{{ item.data.rfqNo }} | {{item.data.chatTeamType}}</span>

              </ng-template>
            </nz-list-item-meta>
          </nz-list-item>
        </ng-template>
      </nz-list>
    </div>
  </ng-template> -->
<!-- <app-stack-notification></app-stack-notification> -->
