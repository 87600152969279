import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
@Component({
  selector: 'app-quick-vendor-detail',
  templateUrl: './quick-vendor-detail.component.html',
  styleUrls: ['./quick-vendor-detail.component.scss']
})
export class QuickVendorDetailComponent implements OnInit {
@Input() vendordetails : any;
vendorId : any;
  constructor(private _router : Router,
    private _drawerRef: NzDrawerRef,) { }

  ngOnInit(): void {
  }
  close(): void {
    this._drawerRef.close({keepDrawer: true});
  }
  gotoDetails(_id: string){
    this.close();
    this._router.navigateByUrl(`main/vendors/${_id}/configuration`)
  }

}
