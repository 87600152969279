<a
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzPlacement="bottomRight"
  class="headerprofile"
>
  <nz-avatar nzIcon="user" [nzShape]="'circle'"></nz-avatar>
  <!-- <nz-avatar nzIcon="user" [nzShape]="'circle'"></nz-avatar>{{ firstName }} -->
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul
    nz-menu
    nzSelectable
    class="dropdown-Menu"
    style="width: -webkit-fill-available"
  >
    <li nz-menu-item>{{ userDetails }}</li>
    <!-- <li nz-menu-divider></li> -->
    <!-- <li nz-menu-item routerLink="/main/profile">Profile</li> -->
    <!-- <li nz-menu-divider></li> -->
    <li nz-menu-item (click)="changePassword()">Change Password</li>
    <li nz-menu-item (click)="signOut()">Logout</li>
  </ul>
</nz-dropdown-menu>
