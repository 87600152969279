import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common/services/auth.service';
import { NzPlacementType } from 'ng-zorro-antd/dropdown';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RequesterService } from 'src/app/common/services/requester.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;
  userDetails = '';
  firstName = '';
  constructor(
    private authService: AuthService,
    private modalService: NzModalService
  ) {}

  ngOnInit() {
    this.authService.profileUpdate.subscribe((user) => {
      if(user) {
        this.userDetails = user && user.fullName;
        this.firstName = user.fullName.split(' ')[0];
      }
    });
  }

  changePassword() {
    const modal = this.modalService.create({
      nzTitle: 'Change Password',
      nzContent: ChangePasswordComponent,
      nzComponentParams: {
        // allRequest: this.userData
      },
      nzFooter: null,
    });
  }

  signOut() {
    this.authService.SignOut();
  }
}
