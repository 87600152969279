<nz-result
  [nzIcon]="iconTemp"
  [nzTitle]="'Search Attender'"
  [nzSubTitle]="
    'Try searching by RFQ No / Owner Name / Job Status / Invoice No. etc.!'
  "
  *ngIf="!keyword"
  class="search-results"
>
  <ng-template #iconTemp>
    <i nz-icon nzType="search"></i>
  </ng-template>
</nz-result>

<div *ngIf="keyword">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title
      >Search result for "{{ keyword }}"</nz-page-header-title
    >
    <nz-page-header-subtitle></nz-page-header-subtitle>
    <nz-page-header-extra>
      <button
        nz-button
        [nzType]="advanceSearch ? 'primary' : 'default'"
        (click)="advanceSearch = !advanceSearch"
      >
        Advanced Search
      </button>
      <button nz-button [nzType]="'link'" (click)="onClearEmit()">
        <i nz-icon nzType="close-circle"></i>
      </button>
    </nz-page-header-extra>
    <nz-page-header-content *ngIf="advanceSearch">
      <div nz-row nzType="flex" nzJustify="space-between">
        <div nz-col nzSpan="10">
          <div
            nz-row
            nzType="flex"
            nzJustify="space-between"
            class="flex-align-center"
          >
            <div nz-col nzSpan="6">Filter by Date</div>
            <div nz-col nzSpan="18">
              <nz-range-picker
                [nzFormat]="dateFormat"
                [(ngModel)]="dateFilter"
              ></nz-range-picker>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="10">
          <div
            nz-row
            nzType="flex"
            nzJustify="space-between"
            class="flex-align-center"
          >
            <div nz-col nzSpan="6">Filter By Ports</div>
            <div nz-col nzSpan="18">
              <nz-select
                [nzMaxTagCount]="3"
                [nzMaxTagPlaceholder]="tagPlaceHolder2"
                nzMode="multiple"
                nzPlaceHolder="Please select"
                [(ngModel)]="selectedPorts"
                nzServerSearch
                nzAllowClear
                nzShowSearch
                (nzOnSearch)="getPorts$.next($event)"
              >
                <nz-option
                  *ngFor="let option of allPorts"
                  [nzLabel]="option"
                  [nzValue]="option"
                ></nz-option>
              </nz-select>
              <ng-template #tagPlaceHolder2 let-selectedList>
                and {{ selectedList.length }} more selected
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div nz-row nzType="flex" nzJustify="space-between" class="mt-3">
        <div nz-col nzSpan="10">
          <div
            nz-row
            nzType="flex"
            nzJustify="space-between"
            class="flex-align-center"
          >
            <div nz-col nzSpan="6">Job Status</div>
            <div nz-col nzSpan="18">
              <nz-select
                [nzMaxTagCount]="3"
                [nzMaxTagPlaceholder]="tagPlaceHolder"
                nzMode="multiple"
                nzPlaceHolder="Please select"
                [(ngModel)]="selectedJobStatusList"
              >
                <nz-option
                  *ngFor="let option of jobStatusList"
                  [nzLabel]="option.label"
                  [nzValue]="option.value"
                ></nz-option>
              </nz-select>
              <ng-template #tagPlaceHolder let-selectedList>
                and {{ selectedList.length }} more selected
              </ng-template>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="10">
          <div
            nz-row
            nzType="flex"
            nzJustify="space-between"
            class="flex-align-center mt-3"
          >
            <div nz-col nzSpan="6">Vendors</div>
            <div nz-col nzSpan="18">
              <nz-select
                [nzMaxTagCount]="3"
                [nzMaxTagPlaceholder]="tagPlaceHolder3"
                nzMode="multiple"
                nzPlaceHolder="Please select"
                [(ngModel)]="selectedVendorList"
                nzServerSearch
                nzAllowClear
                nzShowSearch
                (nzOnSearch)="getAllVendors$.next($event)"
              >
                <nz-option
                  *ngFor="let option of allVendors"
                  [nzLabel]="option"
                  [nzValue]="option"
                ></nz-option>
              </nz-select>
              <ng-template #tagPlaceHolder3 let-selectedList>
                and {{ selectedList.length }} more selected
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div nz-row nzType="flex" nzJustify="space-between" class="mt-3">
        <div nz-col nzSpan="10">
          <div
            nz-row
            nzType="flex"
            nzJustify="space-between"
            class="flex-align-center mt-3"
          >
            <div nz-col nzSpan="6">Admin Action</div>
            <div nz-col nzSpan="18">
              <nz-select
                [nzMaxTagCount]="3"
                [nzMaxTagPlaceholder]="tagPlaceHolder4"
                nzMode="multiple"
                nzPlaceHolder="Please select"
                [(ngModel)]="selectedAdminActions"
                nzAllowClear
                nzShowSearch
              >
                <nz-option
                  *ngFor="let option of allActions"
                  [nzLabel]="option.label"
                  [nzValue]="option.value"
                ></nz-option>
              </nz-select>
              <ng-template #tagPlaceHolder4 let-selectedList>
                and {{ selectedList.length }} more selected
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div nz-row nzType="flex" nzJustify="center" class="mt-3">
        <button nz-button [nzType]="'primary'" (click)="searchByFilters()">
          Apply Filters
        </button>

        <button
          style="margin-left: 5px"
          nz-button
          [nzType]="'default'"
          (click)="clearFilters(); searchByFilters()"
        >
          Clear Filters
        </button>
      </div>
    </nz-page-header-content>
  </nz-page-header>

  <nz-spin nzTip="Loading..." [nzSpinning]="searchLoading">
    <div class="result-container">
      <nz-tabset>
        <nz-tab nzTitle="RFQ ({{ allRfqs.length }})">
          <nz-table
            #basicTable
            [nzData]="allRfqs"
            [nzShowPagination]="true"
            [nzPageSize]="pageSize"
          >
            <thead>
              <tr>
                <th>RFQ No</th>
                <th>Owner</th>
                <th>Admin Action</th>
                <th>Ship / Project Name</th>
                <th>Port(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of basicTable.data">
                <td>
                  <a
                    [routerLink]="['/main/manage-job/rfq-summary/' + data._id]"
                    (click)="close()"
                    >{{ data.rfqNo }}</a
                  >
                </td>
                <td>{{ data.ownerCompanyName }}</td>
                <td>
                  <nz-tag
                    style="cursor: pointer"
                    [routerLink]="
                      data.adminAction && data.adminAction.route
                        ? data.adminAction.route
                        : null
                    "
                    [nzColor]="
                      data.adminAction && data.adminAction.action ? 'red' : ''
                    "
                    nz-popover
                    [nzPopoverTitle]="
                      data.adminAction && data.adminAction.actionTip
                        ? 'Info'
                        : null
                    "
                    nzTrigger="hover"
                    nzPlacement="right"
                    [nzPopoverContent]="
                      data.adminAction && data.adminAction.actionTip
                        ? data.adminAction.actionTip
                        : null
                    "
                    [nzPopoverOverlayStyle]="{ 'white-space': 'pre-wrap' }"
                  >
                    {{
                      data.adminAction && data.adminAction.action
                        ? data.adminAction.action
                        : "No Pending Action"
                    }}
                  </nz-tag>
                </td>
                <td>{{ data.ship }}</td>
                <td>
                  <nz-tag *ngFor="let port of data.ports">{{ port }}</nz-tag>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
        <nz-tab nzTitle="BID ({{ allBids.length }})">
          <nz-table
            #bidTable
            [nzData]="allBids"
            [nzShowPagination]="true"
            [nzPageSize]="pageSize"
          >
            <thead>
              <tr>
                <th>RFQ No</th>
                <th>Vendor</th>
                <th>Vendor Status</th>
                <th>Ship / Project Name</th>
                <th>Port(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of bidTable.data">
                <td>
                  <a
                    [routerLink]="['/main/manage-job/rfq-summary/' + data.rfq]"
                    (click)="close()"
                    >{{ data.rfqNo }}</a
                  >
                </td>
                <td>{{ data.vendorCompany }}</td>
                <td>
                  <nz-tag nzColor="blue"
                    ><a
                      [routerLink]="[
                        '/main/manage-job/bids-details/' + data._id
                      ]"
                      (click)="close()"
                      >{{ data.bidStatus }}</a
                    ></nz-tag
                  >
                </td>
                <td>{{ data.ship }}</td>
                <td>
                  <nz-tag>{{ data.port }}</nz-tag>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
        <nz-tab nzTitle="PO ({{ allPos.length }})">
          <nz-table
            #poTable
            [nzData]="allPos"
            [nzShowPagination]="true"
            [nzPageSize]="pageSize"
          >
            <thead>
              <tr>
                <th>RFQ No</th>
                <th>PO No</th>
                <th>Owner</th>
                <th>Vendor</th>
                <th>Owner Status</th>
                <th>Vendor Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of poTable.data">
                <td>
                  <a
                    [routerLink]="['/main/manage-job/rfq-summary/' + data.rfq]"
                    (click)="close()"
                    >{{ data.rfqNo }}</a
                  >
                </td>
                <td>{{ data.poNumber }}</td>
                <td>{{ data.ownerCompany }}</td>
                <td>{{ data.vendorCompany }}</td>
                <td>{{ data.ownerPoStatus }}</td>
                <td>{{ data.vendorPoStatus }}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
        <nz-tab nzTitle="INVOICE ({{ allInvoices.length }})">
          <nz-table
            #invoiceTable
            [nzData]="allInvoices"
            [nzShowPagination]="true"
            [nzPageSize]="pageSize"
          >
            <thead>
              <tr>
                <th>RFQ No</th>
                <th>PO No</th>
                <th>INVOICE No</th>
                <th>Owner</th>
                <th>Vendor</th>
                <th>Owner Status</th>
                <th>Vendor Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of invoiceTable.data">
                <td>
                  <a
                    [routerLink]="['/main/manage-job/rfq-summary/' + data._id]"
                    (click)="close()"
                    >{{ data.rfqNo }}</a
                  >
                </td>
                <td>{{ data.poNumber }}</td>
                <td>{{ data.vendorInvoiceNo }}</td>
                <td>{{ data.ownerCompany }}</td>
                <td>{{ data.vendorCompany }}</td>
                <td>{{ data.ownerInvoiceStatus }}</td>
                <td>{{ data.vendorInvoiceStatus }}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
        <nz-tab nzTitle="CHAT ({{ allChats.length }})">
          <nz-table
            #chatTable
            [nzData]="allChats"
            [nzShowPagination]="true"
            [nzPageSize]="pageSize"
          >
            <thead>
              <tr>
                <th>RFQ No</th>
                <th>Chat With</th>
                <th>Chat Snippet</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of chatTable.data">
                <td>
                  <a
                    [routerLink]="['/main/manage-job/rfq-summary/' + data._id]"
                    (click)="close()"
                    >{{ data.rfqNo }}</a
                  >
                </td>
                <td>{{ data.chatWith }}</td>
                <td>
                  <span
                    *ngFor="let chatSnippet of data.chatSnippet.slice(0, 3)"
                    style="display: block"
                    >{{ chatSnippet.messageText }}</span
                  >
                </td>
                <td>
                  <button nz-button nzType="primary" (click)="openChat(data)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-tab>
        <nz-tab
          nzTitle="MASTER RECORDS ({{
            allServices.length + allEquipments.length + allBrands.length
          }})"
        >
          <ng-container *ngIf="!isShowingRFQDetails; else rfqDetailsTemp">
            <nz-spin nzTip="Loading..." [nzSpinning]="rfqDetailsCheckLoader">
              <nz-tabset>
                <nz-tab nzTitle="Service ({{ allServices.length }})">
                  <nz-table
                    #serviceTable
                    [nzData]="allServices"
                    [nzShowPagination]="true"
                    [nzPageSize]="pageSize"
                  >
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Verified</th>
                        <th>Added By</th>
                        <th>Used in RFQ No</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of serviceTable.data">
                        <td>{{ data.serviceName }}</td>
                        <td>{{ data.verified ? "Yes" : "No" }}</td>
                        <td>{{ data.addedBy }}</td>
                        <td>
                          <nz-tag
                            [nzColor]="'#2db7f5'"
                            *ngFor="let rfqNo of data.usedInRfqs.slice(0, 3)"
                            >{{ rfqNo }}</nz-tag
                          >
                          <button
                            nz-button
                            nzType="link"
                            *ngIf="data.usedInRfqs.length"
                            (click)="rfqDetailsShow(data.usedInRfqs)"
                          >
                            Show More
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </nz-tab>
                <nz-tab nzTitle="Equipment ({{ allEquipments.length }})">
                  <nz-table
                    #equipmentTable
                    [nzData]="allEquipments"
                    [nzShowPagination]="true"
                    [nzPageSize]="pageSize"
                  >
                    <thead>
                      <tr>
                        <th>Equipment</th>
                        <th>Status</th>
                        <th>Added By</th>
                        <th>Used in RFQ No</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of equipmentTable.data">
                        <td>{{ data.equipmentName }}</td>
                        <td>{{ data.verified ? "Yes" : "No" }}</td>
                        <td>{{ data.addedBy }}</td>
                        <td>
                          <nz-tag
                            [nzColor]="'#2db7f5'"
                            *ngFor="let rfqNo of data.usedInRfqs.slice(0, 3)"
                            >{{ rfqNo }}</nz-tag
                          >
                          <button
                            nz-button
                            *ngIf="data.usedInRfqs.length"
                            nzType="link"
                            (click)="rfqDetailsShow(data.usedInRfqs)"
                          >
                            Show More
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </nz-tab>
                <nz-tab nzTitle="Brand ({{ allBrands.length }})">
                  <nz-table
                    #brandTable
                    [nzData]="allBrands"
                    [nzShowPagination]="true"
                    [nzPageSize]="pageSize"
                  >
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Status</th>
                        <th>Added By</th>
                        <th>Used in RFQ No</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of brandTable.data">
                        <td>{{ data.brandName }}</td>
                        <td>{{ data.verified ? "Yes" : "No" }}</td>
                        <td>{{ data.addedBy }}</td>
                        <td>
                          <nz-tag
                            [nzColor]="'#2db7f5'"
                            *ngFor="let rfqNo of data.usedInRfqs.slice(0, 3)"
                            >{{ rfqNo }}</nz-tag
                          >
                          <button
                            nz-button
                            *ngIf="data.usedInRfqs.length"
                            nzType="link"
                            (click)="rfqDetailsShow(data.usedInRfqs)"
                          >
                            Show More
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </nz-tab>
              </nz-tabset>
            </nz-spin>
          </ng-container>
          <ng-template #rfqDetailsTemp>
            <nz-page-header
              class="site-page-header"
              (nzBack)="onBack()"
              nzBackIcon
              nzTitle="RFQ Details"
            ></nz-page-header>
            <nz-table
              #rfqDetailsTable
              [nzData]="rfqDetailsArr"
              [nzShowPagination]="true"
              [nzPageSize]="pageSize"
            >
              <thead>
                <tr>
                  <th>RFQ No</th>
                  <th>Owner</th>
                  <th>Owner Status</th>
                  <th>Ship / Project Name</th>
                  <th>Port(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of rfqDetailsTable.data">
                  <td>
                    <a
                      [routerLink]="[
                        '/main/manage-job/rfq-summary/' + data._id
                      ]"
                      (click)="close()"
                      >{{ data.rfqNo }}</a
                    >
                  </td>
                  <td>{{ data.ownerCompanyName }}</td>
                  <td>
                    <nz-tag>{{
                      statusService.jobStatus[data.jobStatus]
                    }}</nz-tag>
                  </td>
                  <td>{{ data.ship }}</td>
                  <td>
                    <nz-tag *ngFor="let port of data.ports">{{ port }}</nz-tag>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </ng-template>
        </nz-tab>
      </nz-tabset>
    </div>
  </nz-spin>
</div>
