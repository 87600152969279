import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { map } from 'rxjs/operators';
import { ChatDispatcherService } from 'src/app/main/chat/services/chat-dispatcher.service';
import { RequesterService } from '../../services/requester.service';
import { StatusService } from '../../services/status.service';

@Component({
  selector: 'app-job-overview',
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.scss']
})
export class JobOverviewComponent implements OnInit {
  @Input() rfq: string;
  rfqDetails: any = {};
  timelineData: any[] = [];
  invoiceDetails: any = {};
  constructor(
    private requesterService: RequesterService,
    public statusService: StatusService,
    private chatDispatcherService: ChatDispatcherService,
    private drawerRef: NzDrawerRef,
    private router: Router
  ) { }

  async ngOnInit(): Promise<any> {
    try {
      this.rfqDetails = await this.getRfqDetails();
      await this.getTimeLine(this.rfq);
      this.getInvoiceDetails('vendor');
    } catch (error) {

    }
  }

  chatWithOwner() {
    this.requesterService.request('get', 'jobs/rfqs/' + this.rfq + '/initiate-chat?chatWith=owner').subscribe(data => {
      this.chatDispatcherService.createOrUpdateChannel(data.data.channel);
    });
  }

  async getRfqDetails(): Promise<any>{
    return this.requesterService.request('get', 'jobs/rfqs/' + this.rfq).pipe(map(data => data.data)).toPromise();
  }

  close(): void {
    this.drawerRef.close({keepDrawer: true});
  }

  openInvoice(): void {
    this.close();
    this.router.navigateByUrl('/main/manage-job/invoice/' + this.rfq);
  }

  async getTimeLine(rfq: string): Promise<void> {
    try {

      const { data } = await this.requesterService.request('get', 'jobs/rfqs/' + this.rfq + '/audit-trail').toPromise();
      this.timelineData = data;
    } catch (error) {

    }
  }
  
  getInvoiceDetails(type) : void{
    try{
      this.requesterService.request('get', 'jobs/invoices/' + type + '/' + this.rfq).subscribe(data => {
        this.invoiceDetails = data.data;
        console.log("invoice api:",this.invoiceDetails)
      })
    }catch(err){

    }
  }

}
