import { Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { StreamChat, ChannelData, Message, User } from 'stream-chat';
import { RequesterService } from '../../../common/services/requester.service';
import { ChatDispatcherService } from '../services/chat-dispatcher.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile} from 'ng-zorro-antd/upload';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UploadFilter } from 'ng-zorro-antd/upload';

import { ResizeEvent } from 'angular-resizable-element';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { CommunicationService } from 'src/app/common/services/communication.service';
@Component({
  selector: 'app-floating-chat',
  templateUrl: './floating-chat.component.html',
  styleUrls: ['./floating-chat.component.scss']
})
export class FloatingChatComponent implements OnInit {
  loading = false;
  contactList = 'vendor';
  data = [

  ];
  messageData = [

  ];
  msgUserId: any;
  openSingle = false;
  singleExpanded = false;
  title = 'angular-chat';
  channel: ChannelData;
  username = '';
  messages: Message[] = [];
  newMessage = '';
  channelList: ChannelData[];
  chatClient: any;
  currentUser: User;
  channelType = 'team';
  companyName = '';
  messageListener: any;
  ownerChatList: ChannelData[] = [];
  vendorChatList: ChannelData[] = [];
  workspaceChatList: ChannelData[] = [];
  unreadCount = 0;
  @ViewChild('scrollElement') content: ElementRef;
  @ViewChild('notificationElement') notificationElement: TemplateRef<{}>;
  notificationData: any = {};
  customStyle: any = {
    width: '450px',
    height: '280px',

  };

  mediaUploadURL = environment.apiUrl + "/api/media";
  fileList: any[] = [];
  customHeaders: any = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  messageSending = false;
  attachmentsLoading = false;
  filters: UploadFilter[] = [
    {
      name: "type",
      fn: (fileList: NzUploadFile[]) => {
        // tslint:disable-next-line:no-bitwise
        // const filterFiles = fileList.filter(
        //   (w) =>
        //     ~[
        //       "image/png",
        //       "image/jpg",
        //       "image/jpeg",
        //       "image/tiff",
        //       "application/pdf",
        //     ].indexOf(w.type)
        // );
        // if (filterFiles.length !== fileList.length) {
        //   this.msg.error(`Some Files are of type`);
        //   return filterFiles;
        // }
        return fileList;
      },
    },
    {
      name: "async",
      fn: (fileList: NzUploadFile[]) => {
        return new Observable((observer: Observer<NzUploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      },
    },
  ];
  limit = 30;

   // tslint:disable-next-line:no-any
   handleChange(info: any): void {
    const fileList = info.fileList;
    // 2. read from response and show file link
    if (info.file.response) {
      info.file.url = info.file.response.url;
    }
    // 3. filter successfully uploaded files according to response from server
    // tslint:disable-next-line:no-any
    this.fileList = fileList.filter((item: any) => {
      if (item.response) {
        return item.response.success;
      }
      return true;
    });
    this.attachmentsLoading = fileList.some(
      (item: any) => !item.response && item.status !== "error"
    );
  }
  removeFile(index: number) {
    this.fileList.splice(index, 1);
  }
  contactListOpen = false;
  get ownerChatCounter() {
    return this.ownerChatList.reduce((acc: any, curr: any) => (acc + curr.state.read[this.currentUser.me.id]['unread_messages']), 0);
  }
  get vendorChatCounter() {
    return this.vendorChatList.reduce((acc: any, curr: any) => (acc + curr.state.read[this.currentUser.me.id]['unread_messages']), 0);
  }
  get workspaceChatCounter() {
    return this.workspaceChatList.reduce((acc: any, curr: any) => (acc + curr.state.read[this.currentUser.me.id]['unread_messages']), 0);
  }
  constructor(
    private requesterService: RequesterService,
    private chatDispatcherService: ChatDispatcherService,
    private notificationService: NzNotificationService,
    private msg: NzMessageService,
    private router: Router,
    private communicationService: CommunicationService
  ) {
    this.communicationService.toggleFloatingChat.subscribe(() => {
      this.contactListOpen = !this.contactListOpen;
    })
   }

  async ngOnInit() {
    try {
      await this.authenticateUser();
      await this.getChannels();
    } catch (e) {
      console.log(e);
    }
    this.chatDispatcherService.openChat.subscribe(cid => {
      this.openChatFromNotification(cid);
    });
    this.chatDispatcherService.channel.subscribe(async data => {
      console.log('Channel Data', data);
      try {
        await this.getChannels();
        const channelIndex = this.channelList.findIndex(x => x.id === data.id);
        if (channelIndex !== -1) {
          await this.openChat(this.channelList[channelIndex]);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  async openChat(channelData: ChannelData) {
    this.companyName = channelData.data.name;
    this.messages = [];
    this.singleExpanded = true;
    this.openSingle = true;
    this.updateScroll();
    await this.openChannel(channelData);
  }
  closeChat() {
    this.messages = [];
    this.openSingle = false;
    this.clearMessageListener();
  }
  async deleteChat() {
    if (this.channel) {
      try {
        const destroy = await this.channel.delete();
        this.closeChat();
        await this.authenticateUser();
        await this.getChannels();
      } catch (e) {
        console.log(e);
      }
    }
  }
  async authenticateUser() {
    try {
      const response = await this.requesterService.request('get', 'auth/authenticate-chat').toPromise();
      const { token } = response.data;
      const apiKey = response.data.api_key;
      this.username = response.data.user.username;
      const name = response.data.user.name;
      this.chatClient = new StreamChat(apiKey);
      this.currentUser = await this.chatClient.setUser(
        {
          id: this.username,
          name,
        },
        token
      );
      console.log(this.currentUser);
      this.unreadCount = this.currentUser.me.total_unread_count;
      this.chatClient.on(event => {
        if (event.type === 'message.new' && event.message.user.id !== this.currentUser.me.id) {
          console.log(event);
          // Only Send Notification if the channel is not Open
          if (!this.channel || this.channel.cid !== event.cid || !this.openSingle) {
            const userType = event.user.userType;
            // this.notificationService.blank(`${event.message.user.name}(${userType})`, event.message.text)
            this.notificationData = {
              userType,
              name: event.message.user.name,
              cid: event.cid,
              message: event.message.text
            };
            this.notificationService.template(this.notificationElement, {
              nzData: {
                userType,
                name: event.message.user.name,
                cid: event.cid,
                message: event.message.text
              }
            });
          }

        }
        if (event.unread_count !== undefined) {
          this.getChannels();
        }

        if (event.total_unread_count !== undefined) {
          console.log('TOTAL_UNREAD_COUNT', event.total_unread_count);
          this.unreadCount = event.total_unread_count;
          if (this.channelList) {
            const unreadChannels = this.channelList.filter(x => x.state.read[this.currentUser.me.id].unread_messages);
            this.chatDispatcherService.notificationCounter.next({ count: this.unreadCount, channelList: unreadChannels });
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  openChatFromNotification(cid: string) {
    const channel = this.channelList.find(x => x.cid === cid);
    this.openChat(channel);
  }
  async getChannels() {
    const filter = {
      type: 'team',
      members: { $in: [`${this.currentUser.me.id}`] },
    };
    const sort = { last_message_at: -1 };

    try {
      this.channelList = await this.chatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
        limit: 30
      });
    } catch (e) {
      console.log(e);
    }
    // this.channelList = this.channelList.filter(x => !!x.data.rfqNo);
    this.vendorChatList = this.channelList.filter(x => x.data.chatTeamType === 'vendor');
    this.ownerChatList = this.channelList.filter(x => x.data.chatTeamType === 'owner');
    this.workspaceChatList = this.channelList.filter(x => x.data.chatTeamType === 'workspace');
    // console.log('Channel List', this.channelList);

    const unreadChannels = this.channelList.filter(x => x.state.read[this.currentUser.me.id].unread_messages);
    this.chatDispatcherService.notificationCounter.next({ count: this.unreadCount, channelList: unreadChannels });
  }
  async openChannel(channelData: ChannelData) {
    this.clearMessageListener();
    const channel = this.chatClient.channel(this.channelType, channelData.id);
    console.log('Recieved Data', channel);
    await channel.watch();
    this.channel = channel;
    this.messages = channel.state.messages;
    this.updateScroll();
    await channel.markRead();
    this.messageListener = this.channel.on('message.new', this.messageEventHandler);
  }
  messageEventHandler = event => {
    this.messages = [...this.messages, event.message];
    this.msgUserId = event.message.user.id;
    console.log("msgUserId:",this.msgUserId);
    // if (event.message.user.id !== this.currentUser.me.id) {
    //   console.log('You have a new Message')
    // }
    this.updateScroll();
  }

  clearMessageListener() {
    if (this.channel) {
      this.channel.off('message.new', this.messageEventHandler);
    }
  }

  async sendMessage() {
    if (this.attachmentsLoading) {
      return;
    }
    this.messageSending = true;

    const fileList = [...this.fileList].map((x) => x.response.data);
    this.fileList = [];
    if (this.newMessage.trim() === "" && !fileList.length) {
      this.messageSending = false;
      return;
    }
    console.log(fileList);
    try {
      await this.channel.sendMessage({
        text: this.newMessage,
        fileAttachments: fileList,
      });
      this.newMessage = "";
      this.messageSending = false;
      if(this.channel.data.chatTeamType === 'owner') {
        await this.attachFilesToRFQ(this.channel.data.rfqId, fileList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async attachFilesToRFQ(rfqId, files) {
    await this.requesterService
      .request("put", "jobs/rfqs/" + rfqId + "/attach-more-files", {
        attachments: files.map((x) => x._id),
      })
      .toPromise();
  }

  updateScroll() {
    setTimeout(_ => {
      this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
    }, 100);
  }

  onResizeEnd(event: ResizeEvent): void {
    console.log('Element was resized', event);
    this.customStyle.width = event.rectangle.width + 'px';
    this.customStyle.height = (event.rectangle.height - 100) + 'px';
  }

  openRfqDetails(rfqId) {
    this.router.navigateByUrl("/main/manage-job/rfq-summary/" + rfqId);
  }

}
