import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-media-box',
  templateUrl: './media-box.component.html',
  styleUrls: ['./media-box.component.scss']
})
export class MediaBoxComponent implements OnInit {
  @Input() files: any[] = [];
  @Input() itemsPerLine = 3;
  @Output() fileDelete: EventEmitter<number> = new EventEmitter<number>();
  @Input() deleteEnabled = false;
  gridStyle = {
    width: (100 / this.itemsPerLine) + '%',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  constructor() { }

  ngOnInit() {
    this.gridStyle.width = (100 / this.itemsPerLine) + '%';
  }

  deleteFile(index: number) {
    this.fileDelete.emit(index);
  }

}
