<form [formGroup]="tariffForm">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired nzFor="tariffName">Tariff Name</nz-form-label>
    <nz-form-control [nzSpan]="14" nzErrorTip="Tariff name is required">
      <input nz-input formControlName="tariffName" id="tariffName" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired nzFor="type">Tariff Type</nz-form-label>
    <nz-form-control [nzSpan]="14" nzErrorTip="Tariff type is required">
      <nz-select formControlName="type" id="type">
        <nz-option nzValue="percentage" nzLabel="Percentage"></nz-option>
        <nz-option nzValue="flat" nzLabel="Flat"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired nzFor="value">Tariff Value</nz-form-label>
    <nz-form-control [nzSpan]="14" nzErrorTip="Tariff value is required">
      <nz-input-number style="width: 100%" formControlName="value" [nzMin]="1" [nzStep]="1"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <div style="width: 100%; text-align: center;">
      <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <nz-divider nzType="vertical"></nz-divider>
    <button nz-button nzType="primary" (click)="addTariff()" [disabled]="tariffForm.invalid" [nzLoading]="addEditLoading">
      <i nz-icon nzType="plus"></i> Add Tariff </button>
    </div>

  </nz-form-item>
</form>
