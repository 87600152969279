import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RequesterService } from 'src/app/common/services/requester.service';
import { StatusService } from 'src/app/common/services/status.service';

@Component({
  selector: 'app-job-po-list',
  templateUrl: './job-po-list.component.html',
  styleUrls: ['./job-po-list.component.scss']
})
export class JobPoListComponent implements OnInit {
  @Input() rfq: any = {};
  @Output() closeDrawer: EventEmitter<any> = new EventEmitter<any>();
  activePo: any = {};
  poHistory: any[] = [];
  constructor(
    private requesterService: RequesterService,
    public statusService: StatusService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getActivePo();
    this.getPoHistory();
  }

  getActivePo(): void {
    this.requesterService.request('get', 'jobs/pos/admin/' + this.rfq.po).subscribe(response => {
      this.activePo = response.data;
    })
  }

  getPoHistory(): void {
    this.requesterService.request('get', 'jobs/pos/po-history/' + this.rfq._id).subscribe(response => {
      this.poHistory = response.data;
    })
  }

  openPO(poId?:string) {
    this.closeDrawer.emit();
    if(poId) {
      this.router.navigateByUrl('/main/manage-job/po-history/' + poId);

    } else {
      this.router.navigateByUrl('/main/manage-job/po-process/' + this.rfq._id);
    }
  }

}
