import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-owner-detail',
  templateUrl: './quick-owner-detail.component.html',
  styleUrls: ['./quick-owner-detail.component.scss']
})
export class QuickOwnerDetailComponent implements OnInit {
@Input() rfq : any;
  constructor() { }

  ngOnInit(): void {
  }

}
