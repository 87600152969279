<div class="controls">
  <button nz-button nzType="link">Clear</button>
</div>
<div class="fileList" [class.showing]="expanded" [class.closed]="!expanded" (click)="onClick($event)">
  <div class="fileInput">Some notification text</div>
  <div class="fileInput">Some notification text</div>
  <div class="fileInput">Some notification text</div>
  <div class="fileInput">Some notification text</div>
  <div class="fileInput">Some notification text</div>
</div>
