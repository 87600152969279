<div class="floating-chat-container">
  <div class="single-chat-window" [ngStyle]="{width: customStyle.width}" *ngIf="openSingle" cdkDrag mwlResizable
    [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event)">
    <div class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }"></div>
    <div class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
    <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="nzHeader" [nzActive]="singleExpanded" [nzExpandedIcon]="'message'" [ngStyle]="{background: '#40a9ff',
        'border-radius': '4px',
        border: '0px',
        padding: '2px'
        }" [nzExtra]="extraTpl" [nzShowArrow]="false">
        <ng-template #nzHeader>
          <button style="cursor:move;" nz-button nzType="link" (click)="$event.stopPropagation()" cdkDragHandle><i
              nz-icon style="color: white;" nzType="drag"></i></button>
          <span (click)="openRfqDetails(channel.data.rfqId);$event.stopPropagation()" style="align-self: center ;">{{companyName.length >20 ?
            ((companyName | slice : 0 :30) +'..') : companyName}}</span>
        </ng-template>
        <ng-template #extraTpl >
          <button nz-button nzType="link" (click)="closeChat();$event.stopPropagation()" style="display:contents" ><i nz-icon
              style="font-size: 25px; color: white;" nzType="close-circle"></i></button>
        </ng-template>
        <div nz-row class="message-list" #scrollElement [ngStyle]="{height: customStyle.height}">
          <nz-list [nzDataSource]="messages" [nzRenderItem]="item" [nzItemLayout]="'horizontal'" [nzLoading]="loading">
            <ng-template #item let-item>
              <!-- <div class="flex-container"> -->
                <nz-list-item [class]="item.user.userType == 'admin' ? 'message-single me': 'message-single him'">
                  <nz-list-item-meta
                    nzAvatar="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name={{item.user.name}}"
                    [nzTitle]="nzTitle" [nzDescription]="messageContent">

                    <ng-template #messageContent>
                      <p style="color: white;" *ngIf="item.user.userType === 'admin'">{{item.text}}</p>
                      <p style="color: black;" *ngIf="item.user.userType !== 'admin'">{{item.text}}</p>
                      <app-media-box [files]="item.fileAttachments || []" [itemsPerLine]="2"  ></app-media-box>
                    </ng-template>

                    <ng-template #nzTitle>
                      <p style="margin-top: -45px;" *ngIf="item.user.userType !== 'admin'"><i style="margin-left:14px;"
                          nz-icon nzType="user" nzTheme="outline"></i>
                        <b>{{item.user.name}}</b> | <small>{{item.created_at | date: 'medium'}}</small>
                      </p>
                      <p style="margin-top: -45px;" *ngIf="item.user.userType === 'admin'"><small>{{item.created_at |
                          date: 'medium'}}</small>
                        |<b> {{item.user.name}}</b> <i style="margin-right:14px;" nz-icon nzType="user"
                          nzTheme="outline"></i></p>
                    </ng-template>
                  </nz-list-item-meta>
                </nz-list-item>
              <!-- </div> -->


              <!-- <nz-list-item 
              [class]="item.user.userType == 'admin' ? 'message-single me': 'message-single him'" *ngIf="currentUser.me.id == msgUserId || currentUser.me.id == item.user.id"
                style="text-align: right;">
                <nz-list-item-meta [nzTitle]="nzTitle"
                  nzAvatar="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name={{item.user.name}}"
                  [nzDescription]="messageContent">

                  <ng-template #messageContent>
                    <p>{{item.text}}</p>
                    <app-media-box [files]="item.fileAttachments || []" [itemsPerLine]="2"></app-media-box>
                  </ng-template>

                  <ng-template #nzTitle>
                    {{ item.user.name }}
                  </ng-template>
                </nz-list-item-meta>
              </nz-list-item> -->

              <!-- <nz-list-item *ngIf="currentUser.me.id != item.user.id" style="text-align: left;">
                <nz-list-item-meta [nzTitle]="nzTitle"
                  nzAvatar="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name={{item.user.name}}"
                  [nzDescription]="messageContent">

                  <ng-template #messageContent>
                    <p>{{item.text}}</p>
                    <app-media-box [files]="item.fileAttachments || []" [itemsPerLine]="2"></app-media-box>
                  </ng-template>

                  <ng-template #nzTitle>
                    {{ item.user.name }}
                  </ng-template>
                </nz-list-item-meta>
              </nz-list-item> -->
            </ng-template>
          </nz-list>
        </div>

        <!-- demo try -->
        <div nz-row class="message-compose-card">
          <div nz-col nzSpan="24">
            <nz-input-group [nzAddOnBefore]="postfixButton" [nzAddOnAfter]="suffixIconButton">
              <textarea nz-input [(ngModel)]="newMessage" placeholder="Type Your Message ... "
                [nzAutosize]="{ minRows: 2, maxRows: 2 }" style="resize: none;"
                (keyup.enter)="sendMessage()"></textarea>
            </nz-input-group>
            <ng-template #suffixIconButton>
              <button nz-button nzType="link" nz-tooltip nzTooltipTitle="Send Message" nzSize="large"
                (click)="sendMessage()"><i nz-icon nzType="send" class="send-icon"></i></button>
            </ng-template>
          </div>

          <ng-template #postfixButton>
            <nz-badge [nzCount]="fileList.length" *ngIf="fileList.length">
              <button [nzLoading]="attachmentsLoading" nz-button nzType="link" nzSize="large" nz-dropdown
                [nzDropdownMenu]="menu" nzTrigger="click" nzPlacement="topLeft">
                <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
              </button>
            </nz-badge>

            <nz-upload [nzAction]="mediaUploadURL" [nzFileList]="fileList" nzMultiple [nzLimit]="5" [nzFilter]="filters"
              (nzChange)="handleChange($event)" [nzShowUploadList]="false" [nzHeaders]="customHeaders">

              <button nz-button nzType="link" nzSize="large" nzSearch>
                <i nz-icon [nzType]="fileList.length? 'plus' : 'paper-clip'" nzTheme="outline"></i>
              </button>
            </nz-upload>
          </ng-template>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item *ngFor="let file of fileList; index as i"
                style="pointer-events: none; display: flex; justify-content: space-between; align-items: center;">
                <span><i nz-icon nzType="paper-clip" nzTheme="outline"></i> {{file.name | slice: 0: 20}}</span>
                <button style="margin-left: 5px; pointer-events: all;" (click)="removeFile(i);$event.stopPropagation();"
                  nz-button nzType="link"> <i nz-icon nzType="delete" nzTheme="outline"></i></button>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <!-- stop -->
        <!-- <div nz-row class="input-area">
          <nz-input-group nzSearch [nzAddOnBefore]="postfixButton" [nzAddOnAfter]="suffixIconButton" nzSize="large">
            <input type="text" [(ngModel)]="newMessage" nz-input placeholder="Type your message.."
              (keyup.enter)="sendMessage()" style="margin-top: 4px;" />
          </nz-input-group>
        </div> -->

        <!--  demo-->
        <!-- <div nz-row>
          <nz-input-group [nzSuffix]="suffixIconButton" [nzPrefix]="postfixButton">
            <input type="text" nz-input [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" placeholder="Type your message.." />
          </nz-input-group>
        </div> -->

        <!-- end -->

      </nz-collapse-panel>
    </nz-collapse>
  </div>


  <!-- <ng-template #suffixIconButton>
    <button nz-button nzGhost nzType="default" nzSearch nzPlacement="topRight" nzSize="small" (click)="sendMessage()">
      <i nz-icon nzType="send" nzTheme="outline" style="color: #08c;"></i></button>
  </ng-template> -->
  <!-- <ng-template #suffixIconButton>
    <button nz-button nzType="default" nzSearch nzPlacement="topRight" nzSize="large" (click)="sendMessage()">
      <i nz-icon nzType="send" nzTheme="outline" style="color: #08c;"></i></button>
  </ng-template>

  <ng-template #postfixButton>
    <nz-badge [nzCount]="fileList.length" *ngIf="fileList.length">
      <button style="width: 100px;" [nzLoading]="attachmentsLoading" nz-button
        style="margin-top: 4px;margin-bottom: 4px;" nzType="link" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click"
        nzPlacement="topLeft">
        <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
      </button>
    </nz-badge>

    <nz-upload [nzAction]="mediaUploadURL" [nzFileList]="fileList" nzMultiple [nzLimit]="5" [nzFilter]="filters"
      (nzChange)="handleChange($event)" [nzShowUploadList]="false" [nzHeaders]="customHeaders">

      <button nz-button nzType="link" nzSize="small" nzSearch>
        <i nz-icon [nzType]="fileList.length? 'plus' : 'paper-clip'" nzTheme="outline"></i>
      </button>
    </nz-upload>
  </ng-template>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item *ngFor="let file of fileList; index as i"
        style="pointer-events: none; display: flex; justify-content: space-between; align-items: center;">
        <span><i nz-icon nzType="paper-clip" nzTheme="outline"></i> {{file.name | slice: 0: 20}}</span>
        <button style="margin-left: 5px; pointer-events: all;" (click)="removeFile(i);$event.stopPropagation();"
          nz-button nzType="link"> <i nz-icon nzType="delete" nzTheme="outline"></i></button>
      </li>
    </ul>
  </nz-dropdown-menu> -->

  <div nz-col class="contact-list">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="chatHeader" [nzActive]="contactListOpen" [nzExpandedIcon]="'message'" [ngStyle]="{background: '#40a9ff',
        'border-radius': '4px',
        border: '0px'
        }">
        <ng-template #chatHeader>
          <div style="width: 100%; display: flex; justify-content: space-between;">
            <span>Recent Chats</span>
            <nz-badge [nzCount]="unreadCount"></nz-badge>
          </div>

        </ng-template>
        <nz-tabset>
          <nz-tab [nzTitle]="vendorTitle">
            <ng-template #vendorTitle>
              <div style="width: 100%; display: flex; justify-content: space-between;">
                Vendor<nz-badge nz-col nzSpan="4" [nzCount]="vendorChatCounter"></nz-badge>
              </div>

            </ng-template>
            <nz-list [nzDataSource]="vendorChatList" [nzRenderItem]="item1" [nzItemLayout]="'horizontal'"
              [nzLoading]="loading" style=" max-height: 60vh;overflow-y: auto;">
              <ng-template #item1 let-item>
                <nz-list-item>
                  <nz-list-item-meta [nzTitle]="nzTitle1"
                    nzAvatar="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name={{item.data.vendorName}}"
                    nzDescription="{{item.data.rfqNo}}">
                    <ng-template #nzTitle1>
                      <div nz-row>
                        <span nz-col nzSpan="20" style="cursor: pointer" (click)="openChat(item)">{{
                          item.data.vendorName }}</span>
                        <nz-badge nz-col nzSpan="4" [nzCount]="item.state.read[currentUser.me.id]['unread_messages']">
                        </nz-badge>
                      </div>
                    </ng-template>
                  </nz-list-item-meta>
                </nz-list-item>
              </ng-template>
            </nz-list>
          </nz-tab>
          <nz-tab [nzTitle]="ownerTitle">
            <ng-template #ownerTitle>
              <div style="width: 100%; display: flex; justify-content: space-between;">
                Owner<nz-badge nz-col nzSpan="4" [nzCount]="ownerChatCounter"></nz-badge>
              </div>

            </ng-template>
            <nz-list [nzDataSource]="ownerChatList" [nzRenderItem]="item" [nzItemLayout]="'horizontal'"
              [nzLoading]="loading" style=" max-height: 60vh;overflow-y: auto;">
              <ng-template #item let-item>
                <nz-list-item>
                  <nz-list-item-meta [nzTitle]="nzTitle"
                    nzAvatar="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name={{item.data.ownerName}}"
                    nzDescription="{{item.data.rfqNo}} | {{item.data.ship}}">
                    <ng-template #nzTitle>
                      <div nz-row>
                        <span nz-col nzSpan="20" style="cursor: pointer" (click)="openChat(item)">{{ item.data.ownerName
                          }}</span>
                        <nz-badge nz-col nzSpan="4" [nzCount]="item.state.read[currentUser.me.id]['unread_messages']">
                        </nz-badge>
                      </div>
                    </ng-template>
                  </nz-list-item-meta>
                </nz-list-item>
              </ng-template>
            </nz-list>
          </nz-tab>

          <nz-tab [nzTitle]="workspaceTitle">
            <ng-template #workspaceTitle>
              <div style="width: 100%; display: flex; justify-content: space-between;">
                Workspace<nz-badge nz-col nzSpan="4" [nzCount]="workspaceChatCounter" style="max-width: 35%;"></nz-badge>
              </div>

            </ng-template>
            <nz-list [nzDataSource]="workspaceChatList" [nzRenderItem]="item3" [nzItemLayout]="'horizontal'"
              [nzLoading]="loading" style=" max-height: 60vh;overflow-y: auto;">
              <ng-template #item3 let-item>
                <nz-list-item>
                  <nz-list-item-meta [nzTitle]="nzTitle2"
                    nzAvatar="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=WS"
                    [nzDescription]="wsDescription">
                    <ng-template #wsDescription>
                      <span style="display: block;">Owner : {{item.data.ownerCompanyName}}</span>
                      <span>Vendor : {{item.data.vendorCompanyName}}</span>

                    </ng-template>
                    <ng-template #nzTitle2>
                      <div nz-row>
                        <span nz-col nzSpan="20" style="cursor: pointer" (click)="openChat(item)">{{ item.data.name
                          }}</span>
                        <nz-badge nz-col nzSpan="4" [nzCount]="item.state.read[currentUser.me.id]['unread_messages']">
                        </nz-badge>
                      </div>
                    </ng-template>
                  </nz-list-item-meta>
                </nz-list-item>
              </ng-template>
            </nz-list>
          </nz-tab>
        </nz-tabset>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>


<ng-template let-notif="data" #notificationElement>
  <div class="ant-notification-notice-content" style="cursor: pointer;" (click)="openChatFromNotification(notif.cid)">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon"><i nz-icon nzType="message"
          style="color: rgb(16, 142, 233);"></i></span>
      <div class="ant-notification-notice-message"><span style="font-weight: bold; color: blue;">{{notif.name}}
          ({{notif.userType}})</span></div>
      <div class="ant-notification-notice-description">
        {{notif.message}}
      </div>
    </div>
  </div>
</ng-template>