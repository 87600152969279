import { Component, OnInit } from '@angular/core';
import { SocketService } from './common/services/socket.service';
// import { SocketService } from './common/demos/socket.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  constructor(
    private socketService: SocketService
  ) { }
  ngOnInit() {
    this.socketService.connect();
    this.socketService.authentication();

  }

}
