<nz-spin nzTip="Uploading..." [nzSpinning]="attachmentsLoading">
    <nz-empty *ngIf="!attachments.length" nzNotFoundContent="No files uploaded"></nz-empty>
    <div class="uploadedFiles" *ngIf="attachments.length">
        <app-media-box [files]="attachments || []" [deleteEnabled]="deleteEnabled" (fileDelete)="removeFile($event)"></app-media-box>
      </div>
    <nz-upload nzType="drag" [nzAction]="mediaUploadURL" [nzFileList]="fileList" nzMultiple [nzLimit]="5"
      [nzFilter]="filters" (nzChange)="handleChange($event)" [nzShowUploadList]="false" [nzHeaders]="customHeaders">
      <p class="ant-upload-text"><span>Click</span> or drop files here</p>
    </nz-upload>
    
  </nz-spin>