import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat/chat.component';
import { ChatSingleFloatingComponent } from './chat-single-floating/chat-single-floating.component';
import { FloatingChatComponent } from './floating-chat/floating-chat.component';
import {SharedModuleModule} from '../../common/shareModule/shareModule';


@NgModule({
  declarations: [ChatComponent, ChatSingleFloatingComponent, FloatingChatComponent],
    exports: [
        ChatSingleFloatingComponent,
        FloatingChatComponent
    ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModuleModule
  ]
})
export class ChatModule { }
