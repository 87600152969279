<div style="text-align: center;">
  <img src="assets/image_files/logo.png" alt="logo" class="sidebar-logo" *ngIf="!isCollapsed">
  <img src="assets/image_files/logo-small.png" width="58" alt="logo" class="sidebar-logo" *ngIf="isCollapsed">
</div>

<ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
  <ng-container *ngFor="let item of menuData">
    <li nz-menu-item *ngIf="!item.children || item.children.length === 0" routerLinkActive="router-link-active"
      [routerLink]="['/' + item.route]" nzMatchRouter><i nz-icon [nzType]="item.icon"></i>
      <span>{{item.title}}</span>
      <nz-badge class="badgeStyle" [nzCount]="unreadMsgTotal || 0" *ngIf="item.key === 'supportChat'"></nz-badge>
    </li>

    <li nz-submenu *ngIf="item.children && item.children.length > 0" [nzTitle]="item.title" [nzIcon]="item.icon"
      routerLinkActive="router-link-active">
      <ul>
        <li nz-menu-item [routerLink]="['/' + data.route]" *ngFor="let data of item.children" nzMatchRouter>{{data.title}}
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
