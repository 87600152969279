import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequesterService } from '../../services/requester.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-tariff-add',
  templateUrl: './tariff-add.component.html',
  styleUrls: ['./tariff-add.component.scss']
})
export class TariffAddComponent implements OnInit {
  tariffForm: FormGroup;
  addEditLoading: boolean = false;
  constructor(private requesterService: RequesterService, private nzModalRef: NzModalRef) {
    this.tariffForm = new FormGroup({
      tariffName: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    if(this.nzModalRef) {
      this.nzModalRef.destroy();
    }
  }

  addTariff(): void {
    this.addEditLoading = true;
    console.log('Button ok clicked!', this.tariffForm.value);
    // Validate the form and mark all fields as touched to show errors
    for (const i in this.tariffForm.controls) {
      this.tariffForm.controls[i].markAsDirty();
      this.tariffForm.controls[i].updateValueAndValidity();
    }
    const url = 'master-resources/tariffs';
    const method = 'post';
    if (this.tariffForm.valid) {
      this.requesterService.request(method, url, this.tariffForm.value).subscribe(data => {
        this.addEditLoading = false;
        this.nzModalRef.close(data.data);
      }, err => {
        console.log(err);
      });
    } else {
      this.addEditLoading = false;
    }
  }

}
