import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(

    private router: Router) { }
  canActivate(): boolean {
    console.log('LOGGED => ', AuthService.isLoggedIn);
    if (!AuthService.isLoggedIn) {
      this.router.navigateByUrl('auth');
    }

    return AuthService.isLoggedIn;


  }


}
