<div>
    <form [formGroup]="changePasswordForm">
        <div nz-row nzGutter="10">
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="oldPassword" nzRequired>Old Password</nz-form-label>
                    <nz-form-control nzErrorTip="Should not be blank">
                        <input nz-input id="oldPassword" type="password" formControlName="oldPassword" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="password" nzRequired>New Password</nz-form-label>
                    <nz-form-control nzErrorTip="Should not be blank">
                        <input nz-input id="newPassword" type="password" formControlName="newPassword" placeholder="Enter New Password" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="confirmPassword" nzRequired>Confirm Password</nz-form-label>
                    <nz-form-control [nzErrorTip]="errorTplcheckPassword">
                        <input nz-input id="confirmPassword" type="password" formControlName="confirmPassword" placeholder="Enter Confirm Password" />
                        <ng-template #errorTplcheckPassword let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Please confirm your password!
                            </ng-container>
                            <ng-container *ngIf="control.hasError('confirm')">
                                The confirm password should be match the password!
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row nzJustify="end">
            <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
        Submit
      </button>
        </div>
    </form>
</div>
