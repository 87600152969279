import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Socket } from 'ngx-socket-io';
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket, private msg: NzMessageService) {
    this.socket.on('connect',socket=>{
      this.authentication();
      console.log('socket authenticate && connected')
    })

  }
  connect() {
    this.socket.connect();
  }
  disconnect() {
    this.socket.disconnect();
  }

  sendMessage(key: string, msg: any, report = null) {
    this.socket.emit(key, msg, (err, response) => {
      if(report) {
        return report(err, response);
      }
    });
  }
  getMessage(key: string): any {
    return this.socket
      .fromEvent(key);
  }

  authentication() {
    const token = localStorage.getItem('token');
    this.sendMessage('authentication', { token }, (report) => {
      if(report) {
        // this.msg.error(report);
        return;
      }
      this.sendMessage('testing', { something: true }, (err) => {
        if(!err) {
          console.log('>> Socket connection verified!');
        } else {
          this.msg.error(err);
        }
      });
    });
  }

  public get rawSocket(): Socket {
    return this.socket;
  }
}
