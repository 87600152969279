import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StackNotificationService {
  private notificationSubject = new Subject<any>();
  private index = 0;
  constructor() { }

  getObservable(): Observable<any> {
    return this.notificationSubject.asObservable();
  }

  create(notificationText: string, routerPath: string, notificationGroup: string, notificationData: any) {
    this.notificationSubject.next({notificationText, routerPath});
  }

}
