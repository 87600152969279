import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzTableModule } from 'ng-zorro-antd/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { RoundUp } from '../pipes/RoundUp';
import { ResizableModule } from 'angular-resizable-element';
import { FilePreviewComponent } from '../components/file-preview/file-preview.component';
import { MediaBoxComponent } from '../components/media-box/media-box.component';
import { UploadBoxComponent } from '../components/upload-box/upload-box.component';
import {StackNotificationComponent} from '../components/stack-notification/stack-notification.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { BidsOnRfqComponent } from 'src/app/main/manage-job/bids-on-rfq/bids-on-rfq.component';
import { RouterModule } from '@angular/router';
import { JobPoListComponent } from './job-overview/job-po-list/job-po-list.component';
import { CountryNamePipe } from '../pipes/country-name.pipe';
import { HighlightSearchPipe } from '../pipes/highlight-search.pipe';
import { QuickOwnerDetailComponent } from './job-overview/quick-owner-detail/quick-owner-detail.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { QuickVendorDetailComponent } from './job-overview/quick-vendor-detail/quick-vendor-detail.component';
import { TariffAddComponent } from './tariff-add/tariff-add.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NgxEditorModule } from 'ngx-editor';

const Modules = [
  CommonModule,
  NzFormModule,
  NzSpaceModule,
  ReactiveFormsModule,
  NzGridModule,
  NzSelectModule,
  FormsModule,
  NzPageHeaderModule,
  NzResultModule,
  NzListModule,
  NzAnchorModule,
  NzTableModule,
  DragDropModule,
  NzInputNumberModule,
  ResizableModule,

  NzButtonModule,
  NzIconModule,
  NzTypographyModule,
  NzLayoutModule,
  NzDropDownModule,
  NzBreadCrumbModule,
  NzMenuModule,
  NzPaginationModule,
  NzStepsModule,
  NzAutocompleteModule,
  NzCascaderModule,
  NzCheckboxModule,
  NzDatePickerModule,
  NzInputModule,
  NzRadioModule,
  NzUploadModule,
  NzAvatarModule,
  NzBadgeModule,
  NzCalendarModule,
  NzCardModule,
  NzCollapseModule,
  NzDescriptionsModule,
  NzEmptyModule,
  NzPopoverModule,
  NzStatisticModule,
  NzTabsModule,
  NzTagModule,
  NzToolTipModule,
  NzAlertModule,
  NzDrawerModule,
  NzMessageModule,
  NzModalModule,
  NzNotificationModule,
  NzPopconfirmModule,
  NzSkeletonModule,
  NzSpinModule,
  NzDividerModule,
  NzSwitchModule,
  NzBackTopModule,
  NzProgressModule,
  NzTimePickerModule,
  NzTreeModule,
  RouterModule,
  NzRateModule,
  NzTimelineModule,
  NzImageModule,
  NgxEditorModule,
];

@NgModule({
    declarations: [
        RoundUp,
        UploadBoxComponent,
        MediaBoxComponent,
        FilePreviewComponent,
        StackNotificationComponent,
        JobOverviewComponent,
        BidsOnRfqComponent,
        JobPoListComponent,
        QuickOwnerDetailComponent,
        QuickVendorDetailComponent,
        CountryNamePipe,
        HighlightSearchPipe,
        TariffAddComponent
    ],
    imports: [...Modules],
    exports: [
        ...Modules,
        RoundUp,
        CountryNamePipe,
        UploadBoxComponent,
        MediaBoxComponent,
        FilePreviewComponent,
        StackNotificationComponent,
        TariffAddComponent,
        QuickVendorDetailComponent,
        JobOverviewComponent, BidsOnRfqComponent, QuickOwnerDetailComponent,HighlightSearchPipe
    ],
    providers: []
})
export class SharedModuleModule {}
